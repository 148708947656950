import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import './afk-guide.scss';
import { AFKCharacter } from '../../../modules/afk/common/components/afk-character';
import { Col, Row, Tab, Tabs } from 'react-bootstrap';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';
import { AFKItem } from '../../../modules/afk/common/components/afk-item';

const AFKGuidesDreamNecro: React.FC = () => {
  return (
    <DashboardLayout
      className={'generic-page afk-journey afk-guide'}
      game="afk"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/afk-journey/">AFK Journey</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/afk-journey/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Dream Realm - Necrodrakon</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/afk/categories/category_nekro.jpg"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>AFK Journey Necrodrakon</h1>
          <h2>
            Dream Realm Necrodrakon guide that explains the boss mechanics and
            how to beat it!
          </h2>
          <p>
            Last updated: <strong>11/12/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Necrodrakon" />
        <StaticImage
          src="../../../images/afk/generic/boss_necro.webp"
          alt="Guides"
        />
        <p>
          <strong>Necrodrakon</strong> is one of the 8 available Dream Realm
          bosses you will encounter in AFK Journey. In this guide we will go
          over the boss mechanics and the best characters and teams to use
          against him.
        </p>
        <SectionHeader title="What’s the meta team?" />
        <p>
          Realistically speaking,{' '}
          <strong>
            your meta team will depend on your specific box, and your account
            focus
          </strong>
          , so use your highest rarity DPS carry characters, along with whoever
          can best boost their damage.{' '}
          <strong className="gold">
            Try running Dual carry, as currently there is no way to boost a
            Hypercarry’s damage high enough to make it worth running only one
          </strong>
          . Keep faction bonus in mind but don’t run suboptimal characters just
          to force it.
        </p>
        <p>
          Still, below you can find a few example teams. Big thanks to{' '}
          <StaticImage
            src="../../../images/afk/icons/afkanalytica.png"
            alt="AFK Analytica"
          />{' '}
          <strong className="yellow">AFK Analytica</strong> for the team
          building tool. You can find their website{' '}
          <a
            href="https://www.afkanalytica.com/"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>{' '}
          and their discord{' '}
          <a
            href="https://www.discord.gg/analytica"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          .
        </p>
        <SectionHeader title="Season 2 Teams (Endless)" />
        <Tabs
          defaultActiveKey="Team1"
          transition={false}
          id="boss-tabs-season"
          className="nav-tabs"
        >
          <Tab
            eventKey="Team1"
            title={<>Team #1 - Best Team</>}
            className="with-bg"
          >
            <h5>Team #1 - Best Team (Whale - Harak + Phraesto)</h5>
            <StaticImage
              src="../../../images/afk/dream_s/necro_1.webp"
              alt="Guides"
            />
            <p>
              Credits to <strong>XeroStar</strong> from AFK Analytica Discord.
            </p>
            <p>Character information:</p>
            <ul className="bigger-margin">
              <li>
                <AFKCharacter mode="inline" slug="harak" enablePopover /> is the
                main carry.
              </li>
              <li>
                <AFKCharacter mode="inline" slug="phraesto" enablePopover /> is
                the tank and secondary carry, as well as energy regen source
              </li>
              <li>
                <AFKCharacter mode="inline" slug="sonja" enablePopover /> acts
                as buffer and secondary carry.
              </li>
              <li>
                <AFKCharacter mode="inline" slug="mikola" enablePopover /> acts
                as buffer and healer.
              </li>
              <li>
                <AFKCharacter mode="inline" slug="kruger" enablePopover />
                acts as debuffer.
              </li>
              <li>Artifacts:</li>
              <ul>
                <li>
                  <AFKItem name="Resonating Spell" /> - Best overall
                  performance.
                </li>
              </ul>
            </ul>
          </Tab>
          <Tab
            eventKey="Team2"
            title={<>Team #2 - Alt Team</>}
            className="with-bg"
          >
            <h5>Team #2 - Alt Team (Harak, No Phraesto)</h5>
            <StaticImage
              src="../../../images/afk/dream_s/necro_2.webp"
              alt="Guides"
            />
            <p>
              Credits to <strong>ChuTe</strong> from AFK Analytica Discord.
            </p>
            <p>Character information:</p>
            <ul className="bigger-margin">
              <li>
                <AFKCharacter mode="inline" slug="harak" enablePopover /> is the
                main carry.
              </li>
              <li>
                <AFKCharacter mode="inline" slug="shakir" enablePopover /> is
                the tank and buffer. <strong>(EX+ 10 required)</strong>
              </li>

              <li>
                <AFKCharacter mode="inline" slug="sonja" enablePopover /> acts
                as buffer and secondary carry.
              </li>

              <li>
                <AFKCharacter mode="inline" slug="mikola" enablePopover /> acts
                as healer and buffer.
              </li>
              <li>
                <AFKCharacter mode="inline" slug="kruger" enablePopover /> acts
                as debuffer.
              </li>
              <li>Artifacts:</li>
              <ul>
                <li>
                  <AFKItem name="Resonating Spell" /> - Best overall
                  performance.
                </li>
              </ul>
            </ul>
          </Tab>
          <Tab
            eventKey="Team3"
            title={<>Team #3 - Alt Team</>}
            className="with-bg"
          >
            <h5>Team #3 - Alt Team (Phraesto)</h5>
            <StaticImage
              src="../../../images/afk/dream_s/necro_3.webp"
              alt="Guides"
            />
            <p>
              Credits to <strong>Saber[S21]</strong> from AFK Analytica Discord.
            </p>
            <p>Character information:</p>
            <ul className="bigger-margin">
              <li>
                <AFKCharacter mode="inline" slug="lily-may" enablePopover /> is
                the main carry.
              </li>
              <li>
                <AFKCharacter mode="inline" slug="ludovic" enablePopover /> is
                the healer and secondary carry.
              </li>
              <li>
                <AFKCharacter mode="inline" slug="phraesto" enablePopover /> is
                the tank, secondary carry and energy regen source.
              </li>
              <li>
                <AFKCharacter mode="inline" slug="mikola" enablePopover /> acts
                as healer and buffer.
              </li>
              <li>
                <AFKCharacter
                  mode="inline"
                  slug="smokey-and-meerky"
                  enablePopover
                />{' '}
                acts as healer and buffer and damage dealer
              </li>
              <li>Artifacts:</li>
              <ul>
                <li>
                  <AFKItem name="Verdant Spell" /> - Best overall performance.
                </li>
              </ul>
            </ul>
          </Tab>
          <Tab
            eventKey="Team4"
            title={<>Team #4 - No Celehypo</>}
            className="with-bg"
          >
            <h5>Team #4 - No Celehypo (Sonja + Lily May)</h5>
            <StaticImage
              src="../../../images/afk/dream_s/necro_4.webp"
              alt="Guides"
            />
            <p>
              Credits to <strong>Eri</strong> from Prydwen.
            </p>
            <p>Character information:</p>
            <ul className="bigger-margin">
              <li>
                <AFKCharacter mode="inline" slug="lily-may" enablePopover /> is
                the main carry.
              </li>
              <ul>
                <li>
                  <AFKCharacter mode="inline" slug="marilee" enablePopover />{' '}
                  can be used as an alternative to Lily May.
                </li>
              </ul>
              <li>
                <AFKCharacter mode="inline" slug="alsa" enablePopover /> acts as
                secondary carry.
              </li>
              <li>
                <AFKCharacter mode="inline" slug="sonja" enablePopover /> acts
                as buffer and secondary carry.
              </li>
              <ul>
                <li>
                  <AFKCharacter mode="inline" slug="shakir" enablePopover /> can
                  be used as an alternative to Sonja.
                </li>
                <li>
                  <AFKCharacter mode="inline" slug="korin" enablePopover /> can
                  be used as an alternative to Sonja.
                </li>
                <li>
                  <AFKCharacter mode="inline" slug="temesia" enablePopover />{' '}
                  can be another alternative, with more self sustain and less
                  damage.
                </li>
              </ul>
              <li>
                <AFKCharacter
                  mode="inline"
                  slug="smokey-and-meerky"
                  enablePopover
                />{' '}
                is used for buffs, healing and Damage Resistance.
              </li>
              <li>
                <AFKCharacter mode="inline" slug="mikola" enablePopover /> acts
                as buffer and healer.
              </li>
              <li>Artifacts:</li>
              <ul>
                <li>
                  <AFKItem name="Resonating Spell" /> - Best overall
                  performance.
                </li>
              </ul>
            </ul>
          </Tab>
        </Tabs>
        <SectionHeader title="Boss Mechanics" />
        <p>
          The boss will start by designating the player’s closest character as
          the “tank” and will start by attacking that character, but will switch
          target to the next closest character if the tank moves out of range.
          In early to late game, your best bet for the tank role in this boss
          will be <AFKCharacter mode="inline" slug="thoran" enablePopover /> and
          surprisingly{' '}
          <AFKCharacter mode="inline" slug="temesia" enablePopover />. This is
          because Necrodrakon has two scary abilities that punish characters who
          just stand around in one place:
        </p>
        <ul>
          <li>
            In-between attacks, Necrodrakon will cast a <strong>“Crest”</strong>{' '}
            on every character. This crest is a bomb that will explode if the
            character doesn’t move for 15s, dealing high damage and also
            debuffing that character’s ATK and energy gain,
          </li>
          <li>
            Necrodrakon will also cast an{' '}
            <strong>
              Area Denial Attack on the biggest concentration of heroes
            </strong>{' '}
            (which depending on your team can be your frontline, or your
            backline). So you really don’t want to bring more than 2 backline
            heroes to this fight, or else they will have to deal with this
            attack for the entire fight. Though as long as your chosen heroes
            move around a bunch they will have no trouble dealing with this
            attack.
          </li>
        </ul>
        <p>
          Other than these, Necrodrakon will also cast a{' '}
          <strong>big AOE attack</strong>, dealing heavy damage to all heroes, a
          total of 3 times during the fight. But there is a small issue. This
          stage is where{' '}
          <AFKCharacter mode="inline" slug="smokey-and-meerky" enablePopover />{' '}
          and to a lesser extent other healers hits their first big wall,
          because of Necrodrakon last attack:
        </p>
        <ul>
          <li>
            A ranged interrupt attack which targets the 2 healthiest heroes,
            which naturally will often include your healer.
          </li>
        </ul>
        <p>
          So your healer will be constantly interrupted, forcing us to go with a
          different healing strategy, one focused on heroes with life leech or
          shield abilities. Example:
        </p>
        <ul className="bigger-margin">
          <li>
            <AFKCharacter mode="inline" slug="temesia" enablePopover />
          </li>
          <li>
            <AFKCharacter mode="inline" slug="korin" enablePopover />
          </li>
          <li>
            <AFKCharacter mode="inline" slug="seth" enablePopover />
          </li>
          <li>
            <AFKCharacter mode="inline" slug="shakir" enablePopover />
          </li>
          <li>
            <AFKCharacter mode="inline" slug="kruger" enablePopover />
          </li>
          <li>
            <AFKCharacter mode="inline" slug="thoran" enablePopover />
          </li>
          <li>
            <AFKCharacter mode="inline" slug="reinier" enablePopover />
          </li>
        </ul>
        <p>
          Of course, we can still fall back to using{' '}
          <AFKCharacter mode="inline" slug="smokey-and-meerky" enablePopover />{' '}
          but he won’t be as effective here as he is on Skyclops and King
          Croaker.
        </p>
        <SectionHeader title="Action Summary" />
        <p>
          Necrodrakon takes about <strong>38-39</strong> actions in the span of
          90 seconds (the full length of the battle - if you survive through all
          of it that is). Here's a list of all possible actions:
        </p>
        <ul>
          <li>Basic Melee Attack,</li>
          <li>Basic Ranged Attack,</li>
          <li>
            Area Denial attack (9x – targets area with the most characters),
          </li>
          <li>Teamwide AOE Attack (3x),</li>
          <li>Teamwide Crest Cast (5x),</li>
          <li>
            Ranged Interrupt Attack (7x – targets 2 healthiest characters).
          </li>
        </ul>
        <SectionHeader title="Videos" />
        <p>For more team idea, check this video:</p>
        <Row className="video">
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="nmCOWLzZZOc" />
          </Col>
        </Row>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default AFKGuidesDreamNecro;

export const Head: React.FC = () => (
  <Seo
    title="Necrodrakon | AFK Journey | Prydwen Institute"
    description="Dream Realm Necrodrakon guide that explains the boss mechanics and how to beat it!"
    game="afk"
  />
);
